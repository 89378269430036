
.tender-screen-main {
  background-color: $primary-bg-color;
  flex: 5;
  padding: 10px;
  box-sizing: border-box;
  .tender-tabs {
    display: flex;
    flex-direction: row;
    .tab-heading {
      flex: 1;
      margin: 0 5px;
      &:first-child { margin-left: 0; }
      &:last-child { margin-right: 0; }
    }
  }
}
.products {
  background-color: $product-list-bg-color;
  color: $product-list-text-color;
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  .product {
    border: 1px solid $product-list-border-color;
    margin: 10px 20px;
    padding: 10px 20px;
    font-size: 1.8em;
    .value {
      float: right;
    }
  }
}
.tender-screen-side {
  flex: 3;
  padding: 10px;
  box-sizing: border-box;
  color: $tab-text-color;
  font-weight: 100;
  background-color: $primary-bg-color;
  &.bold {
    font-weight: 300;
  }
  .value {
    float: right;
  }
  .notes {
    flex-grow: 3;
    display: flex;
    flex-direction: column;
    padding: 10px;
    max-height: 30%;
    background-color: $notes-bg-color;
    font-size: 2em;
    .header {
      font-size: 0.8em;
      border-bottom: 3px solid $purchase-list-line-color;
      padding: 5px;
      .clear-notes {
        float: right;
        font-size: 0.8em;
        vertical-align: bottom;
      }
    }
  }
  textarea {
    flex-grow: 1;
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    margin-top: 5px;
    border: none;
    background: transparent;
    color: $notes-text-color;
    font-size: 20px;
    font-weight: 100;
    padding: 10px;
    &::-webkit-input-placeholder {
      color: $notes-help-color;
      text-align: justify;
      font-weight: 100;
      font-style: italic;
    }
  }
  .purchase-list {
    color: $purchase-list-text-color;
    background-color: $purchase-list-bg-color;
    margin: 10px 0;
    padding: 10px;
    flex: 1;
    display: flex;
    flex-direction: column;
    .heading {
      font-size: 1.6em;
      border-bottom: 3px solid $purchase-list-line-color;
      padding: 5px;
      .clear-list {
        float: right;
        font-size: 0.8em;
        vertical-align: bottom;
      }
    }
    .list {
      flex: 1;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      color: $purchase-list-line-color;
      font-weight: 300;
      font-size: 1.4em;
      line-height: 1.6em;
      .delete {
        color: white;
        float: right;
        margin-left: 5px;
        background-color: red;
        padding: 0 10px;
      }
    }
    .total {
      border-top: 3px solid $purchase-list-text-color;
      .text {
        font-size: 1.3em;
      }
      .value {
        font-size: 3.6em;
        font-weight: 300;
        color: $purchase-green-text;
        &::first-letter {
          font-size: 0.7em;
        }
      }
      &.refund .value {
        color: $refund-red-text;
      }
    }
  }
  .tender {
    border: 4px solid $tender-button-border;
    font-size: 2em;
    line-height: 3em;
    .text {
      display: block;
      text-align: center;
    }
    &.purchase {
      border-color: $tender-button-border;
      color: $tender-button-text;
      background-color: $tender-button-bg;
    }
    &.refund {
      border-color: $refund-button-border;
      color: $refund-button-text;
      background-color: $refund-button-bg;
    }
  }
}

.calculator-wrapper {
  flex: 1;
  background-color: $primary-bg-color;
}

.amount-pane {
  flex: 1;
  background-color: $amount-pane;
  position: relative;
  border-bottom: 5px solid $purchase-green;
  &.refund {
    border-bottom: 5px solid $refund-red;
  }
  .text {
    position: absolute;
    top: 10%;
    left: 2%;
    font-size: 30px;
    color: $grey-text;
  }
  .cancel {
    position: absolute;
    top: 10%;
    right: 2%;
    color: $grey-text;
  }
  .running-total {
    position: absolute;
    top: 10%;
    left: 2%;
    bottom: 10%;
    color: #636363;
    width: 110px;
    padding-right: 10px;
    font-size: 20px;
    line-height: 30px;
    text-align: right;
    overflow-y: auto;
  }
  .calc-modal & .running-total {
    margin-top: 35px;
  }
  .value {
    position: absolute;
    top: 50%;
    right: 10%;
    font-size: 90px;
    line-height: 100px;
    min-width: 50%;
    text-align: right;
    margin-top: -50px;
    color: $purchase-green-text;
    &.refund {
      color: $refund-red-text;
    }
    &.empty {
      color: #636363;
    }
    &::first-letter {
      font-size: 0.8em;
    }
  }
  .clear {
    position: absolute;
    top: 50%;
    left: 90%;
    width: 38px;
    height: 38px;
    margin-top: -16px;
    margin-left: 10px;
    background-image: url(/images/clear.png);
  }
}

.calculator {
  flex: 3;
  display: flex;
  flex-direction: column;
  margin: -5px;
  margin-top: 5px;
  .calculator-row {
    flex-grow: 1;
    flex-basis: 25%;
    display: flex;
    flex-direction: row;
    .button,
    .calc-spacer {
      flex-basis: 25%;
      margin: 5px;
    }
    .button {
      font-size: 3em;
      color: $calc-text-color;
      background-color: $calc-number-up;
      display: flex;
      align-items: center;
      text-align: center;
      box-sizing: border-box;
      //border-bottom: 3px solid $calc-number-border;
      &.function {
        background-color: $calc-func-up;
      }
      & > span {
        width: 100%;
      }
      &.depressed,
      &:active {
        //border-top: 3px solid $calc-number-border;
        //border-bottom: none;
        background-color: $calc-number-down;
      }
      &.function.depressed,
      &.function:active {
        background-color: $calc-func-down;
      }
    }
  }
}

.tab-heading {
  color: $tab-text-color;
  padding: 13px 20px;
  font-weight: 100;
  background: $tab-bg;
  min-height: 40px;
  &.active {
    color: $tab-text-active;
    background: $tab-bg-active;
    font-weight: 300;
    &.refund {
      color: $tab-text-active-refund;
    }
  }
  &.bold {
    font-weight: 300;
  }
  .text {
      font-size: 2em;
  }
  .value {
    float: right;
    font-size: 2em;
  }
  .toggle {
    float: right;
    margin: -5px -14px;
    background-color: $tab-text-active;
  }
  &.refund .toggle {
    background-color: $tab-text-active-refund;
  }
}
.receipt-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  visibility: hidden;
  background-color: rgba(0,0,0,0.0);
  transition: background-color 0.5s ease-in, visibility 1s linear;
  .history-record {
    transition: right 0.5s ease-in;
    position: absolute;
    width: 40%;
    top: 0;
    right: -40%;
    bottom: 0;
    display: flex;
    background-color: white;
    .back {
      position: absolute;
      left: 0;
      padding: 10px;
      vertical-align: middle;
    }
  }
  &.active {
    visibility: visible;
    background-color: rgba(0,0,0,0.5);
    transition: background-color 0.5s ease-in, visibility 0s linear;
    .history-record {
      right: 0%;
    }
  }
}

.calc-modal-bg {
  z-index: 4;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
}
.calc-modal {
  z-index: 5;
}
