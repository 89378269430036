
// New Colors
$primary-color: #0377d6;
$primary-text-color: white;

$training-mode-blue: rgb(46, 68, 159);

$branding-bar-bg: $primary-color;

$primary-bg-color: #232323;
$secondary-bg-color: #323232;

$purchase-green: #00c10e;
$purchase-green-text: #19a723;
$refund-red: #f20611;
$refund-red-text: #db0c0c;

$text-black: black;
$text-faded: rgb(142, 142, 142);

$link-attention: rgb(235, 143, 48);

// Menu
$menu-color: #d6d6d6;
$menu-bg-color: #000000;
$menu-bg-color-active: #2e2e2e; // ?
$menu-purchase-color: $purchase-green;
$menu-bg-purchase-color-active: #0b3e10;
$menu-refund-color: #c80f0f;
$menu-bg-refund-color-active: #360404;
$menu-icon-opacity: 0.5;
$menu-icon-opacity-active: 1.0;
$menu-separator: rgb(77, 77, 77);

// Tender Screen
$amount-pane: #ffffff;

$tab-text-color: #ffffff;
$tab-bg: linear-gradient($secondary-bg-color, $primary-bg-color);
$tab-text-active: $purchase-green-text;
$tab-text-active-refund: $refund-red-text;
$tab-bg-active: #ffffff;

$notes-bg-color: $secondary-bg-color;
$notes-help-color: #6b6b6b;
$notes-text-color: #ffffff;

$tender-button-border: $purchase-green;
$tender-button-bg: #021804;
$tender-button-text: #0aec1a;

$refund-button-border: #f60808; // Different from refund-red!
$refund-button-bg: #180205;
$refund-button-text: #ff2a0e;

$calc-text-color: #ffffff;
$calc-number-up: #343434;
$calc-number-down: #545454; // ?
$calc-func-up: #2f2f2f;
$calc-func-down: #515151; // ?

$purchase-list-line-color: #808080;
$purchase-list-bg-color: #000000;
$purchase-list-text-color: #ffffff;
$purchase-list-item-text-color: #d2d2d2;

$product-list-bg-color: #ffffff;
$product-list-text-color: #525252;
$product-list-border-color: #959595;

$grey-text: #808080;

// Setup
$setup-button-bg: rgba(0, 0, 0, 0.2);
$setup-button-bg-primary: rgba(0, 0, 0, 0.5);

// History
$history-purchase-type: rgb(25, 167, 35);
$history-refund-type: rgb(218, 32, 32);

// Settings
$settings-bg-color: #f2f2f2;
//

$top-bar-height: 60px;

// Scrolbar
::-webkit-scrollbar {
    width:2px;
    height:2px;
    -webkit-border-radius: 2px;
}

::-webkit-scrollbar-track,
::-webkit-scrollbar-track-piece {
    background-color:transparent;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(190, 190, 190, 0.65);
    width: 2px;
    height: 2px;
    -webkit-border-radius:2px;
}

@import "mixins.scss";
@import "menu.scss";
@import "setup.scss";
@import "tender.scss";
@import "history.scss";
@import "settings.scss";

html, body {
  margin: 0;
  height: 100%;
  font-size: 2vmin;
  font-family: Roboto, Helvetica, Arial;
  font-weight: 300;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
body > .ember-view {
  height: 100%;
}

[data-ember-action] {
  cursor: pointer;
}

.top-bar {
  -webkit-app-region: drag;
  position: absolute;
  width: 100%;
  left: 0;
  height: $top-bar-height;
  background-color: $branding-bar-bg;
  @include box-shadow(0, 0, 5px, 0, #2e2e2e);
  z-index: 2;
  .pocket-pay-logo {
    padding: 10px 40px;
  }
  .quest-logo {
    -webkit-app-region: no-drag;
    padding: 10px 40px;
  }
  .icon {
    -webkit-app-region: no-drag;
    float: right;
    padding: 10px;
  }
  .splitter {
    display: inline-block;
    width: 2px;
    height: 30px;
    margin-top: 15px;
    vertical-align: top;
    background-color: #83bceb;
    border-bottom: 2px solid #0266bf;
  }
}
.main-content {
  position: absolute;
  top: $top-bar-height;
  left: 0;
  right: 0;
  bottom: 0;
}

@keyframes update-slidein {
  from {
    bottom: -60px;
  }
  to {
    bottom: 0;
  }
}
.update-notification {
  animation-duration: .8s;
  animation-name: update-slidein;
  position: absolute;
  z-index: 3;
  bottom: 0;
  min-height: 60px;
  box-sizing: border-box;
  line-height: 40px;
  padding: 10px;
  width: 100%;
  background-color: $menu-bg-color;
  border-top: 1px solid $menu-color;
  color: $menu-color;
  font-size: 20px;
  .update {
    color: $link-attention;
    font-weight: 500;
  }
  .ignore {
    color: $link-attention;
    font-weight: 500;
    float: right;
    padding-left: 10px;
    border-left: 1px solid $menu-color;
  }
}

.container {
  display: flex;
  height: 100%;
  flex-direction: row;
}

.fill-height {
  height: 100%;
}

.flex-vertical {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.hidden {
  display: none;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

.selectable {
  -webkit-user-select: initial;
}

// Theme SDK
.ce-card .ce-brand-header {
  background-color: $primary-bg-color;
  color: $primary-text-color;
}
.ce-card button {
  background-color: $secondary-bg-color;
}
.sk-circle .sk-child:before {
  background-color: $secondary-bg-color;
}

// Override for alert box for update info
.ce-card.ce-floating ul.shrink-top {
  margin-top: -20px;
  margin-right: 15px;
}
