
.menu {
  position: relative;
  height: 100%;
  .toggle {
    position: absolute;
    bottom: 0;
    color: $menu-color;
    text-align: center;
    width: 100%;
    height: 40px;
    line-height: 40px;
    background-color: $menu-bg-color;
    border-top: 1px solid $menu-separator;
  }
  ul {
    position: relative;
    width: 80px;
    height: 100%;
    display: block;
    box-sizing: border-box;
    font-size: 20px;
    color: $menu-color;
    background-color: $menu-bg-color;
    list-style-type: none;
    overflow-y: auto;
    margin: 0;
    padding: 0;
    padding-bottom: 40px;
    &:before {
      content:'';
      display:block;
      @include inset-box-shadow(-5px, 0, 10px, -5px, #2e2e2e);
      position:fixed;
      top:0;
      left:0;
      width:inherit;
      height:100%;
      pointer-events:none;
    }
    a {
      text-decoration: none;
      color: $menu-color;
    }
    li {
      overflow: hidden;
      white-space: nowrap;
      height: 80px;
      line-height: 80px;
      text-decoration: none;
      padding: 0 30px 0 15px;
      img {
        opacity: $menu-icon-opacity;
        vertical-align: middle;
        padding: 0 15px 0 0;
      }
      &:hover {
        img { opacity: $menu-icon-opacity-active; }
        background-color: $menu-bg-color-active;
      }
      &.purchase {
        border-left: 5px solid $menu-purchase-color;
        padding-left: 10px;
      }
      &.refund {
        border-left: 5px solid $menu-refund-color;
        padding-left: 10px;
      }
      &.purchase:hover { background-color: $menu-bg-purchase-color-active; }
      &.refund:hover { background-color: $menu-bg-refund-color-active; }
      &.test-mode {
        background-color: $training-mode-blue;
        height: 30px;
        line-height: 30px;
        font-size: 14px;
        img {
          padding: 0 30px 0 15px;
        }
      }
    }
    a.active li {
      img { opacity: $menu-icon-opacity-active; }
      background-color: $menu-bg-color-active;
      &.purchase { background-color: $menu-bg-purchase-color-active; }
      &.refund { background-color: $menu-bg-refund-color-active; }
    }
    li.connection {
      position: relative;
      img { opacity: $menu-icon-opacity-active; }
      &:hover {
        background-color: $menu-bg-color;
      }
      .pinpad-name {
        position: absolute;
        left: 85px;
        top: 22px;
        font-size: 0.8em;
      }
    }
    &.expanded { width: auto; }
  }
}
