
$setting-switch: #2196F3;
$setting-switch-bg: #42A5F5;

.settings-pane {
  flex: 1;
  overflow-y: auto;
  border-left: 1px solid $primary-bg-color;
  background-color: $primary-bg-color;
  color: $primary-text-color;
  &:first-child {
    border-left: none;
  }
  h1 {
    padding: 0 20px;
    text-transform: uppercase;
    font-weight: 300;
  }
  .setting {
    padding: 10px 20px;
    background-color: $secondary-bg-color;
    border-bottom: 1px solid $primary-bg-color;
    h2 {
      display: block;
      margin: 5px 0;
      font-weight: 500;
    }
    .info {
      img {
        vertical-align: middle;
      }
    }
    .checkbox {
      float: right;
      margin: 5px 0;
      padding: 10px;
      vertical-align: middle;
    }
    .small-text-input {
      float: right;
      box-sizing: border-box;
      border: none;
      border-bottom: 3px solid $setting-switch-bg;
      margin-bottom: 3px;
      padding: 5px;
      font-size: 1.1em;
      width: 130px;
    }
    &[data-ember-action] {
      background-color: $setting-switch-bg;
      color: white;
    }
  }
  .product-button {
    display: flex;
    flex-direction: row;
    .position {
      margin: 0 4px 0 0;
      .move {
        display: block;
        border: none;
        background-color: $setting-switch-bg;
        color: white;
        font-size: 1.8em;
        width: 40px;
        &:first-child {
          margin-bottom: 4px;
        }
        &:disabled {
          cursor: not-allowed;
          opacity: 0.5;
        }
      }
    }
    .details {
      flex: 1;
      .name {
        input {
          box-sizing: border-box;
          border: none;
          border-bottom: 3px solid $setting-switch-bg;
          margin-bottom: 3px;
          padding: 5px;
          font-size: 1.6em;
          width: 100%;
        }
      }
      .price {
        font-size: 1.3em;
        input {
          border: none;
          border-bottom: 3px solid $setting-switch-bg;
          margin-bottom: 3px;
          padding: 5px;
          width: 120px;
          text-align: right;
          font-size: 1em;
        }
      }
      .delete {
        float: right;
        border: none;
        background-color: $refund-red;
        color: white;
        padding: 5px 10px;
        font-size: 1.3em;
      }
    }
  }
}

.onoffswitch {
    position: relative; width: 55px;
    -webkit-user-select:none; -moz-user-select:none; -ms-user-select: none;
}
.onoffswitch-checkbox {
    display: none;
}
.onoffswitch-label {
    display: block; overflow: hidden; cursor: pointer;
    height: 20px; padding: 0; line-height: 20px;
    border: 0px solid #FFFFFF; border-radius: 30px;
    background-color: #9E9E9E;
    transition: background-color 0.05s ease-in;
}
.onoffswitch-label:before {
    content: "";
    display: block; width: 30px; margin: -5px;
    background: #FFFFFF;
    position: absolute; top: 0; bottom: 0;
    right: 31px;
    border-radius: 30px;
    box-shadow: 0 6px 12px 0px #757575;
    transition: all 0.05s ease-in 0s;
}
.onoffswitch-checkbox:checked + .onoffswitch-label {
    background-color: $setting-switch-bg;
}
.onoffswitch-checkbox:checked + .onoffswitch-label, .onoffswitch-checkbox:checked + .onoffswitch-label:before {
   border-color: $setting-switch-bg;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
    margin-left: 0;
}
.onoffswitch-checkbox:checked + .onoffswitch-label:before {
    right: 0px;
    background-color: $setting-switch;
    box-shadow: 3px 6px 18px 0px rgba(0, 0, 0, 0.2);
}
