
.history-list {
  flex: 1;
  overflow-y: auto;
  position: relative;
  background-color: $primary-bg-color;
  color: $primary-text-color;
  .row {
    min-height: 50px;
    border-bottom: 1px solid $menu-separator;
    &::after { // Clear
      display: block;
      content: '';
      clear:both;
    }
    &.active {
      border-right: 14px solid $primary-color;
    }
    &.declined {
      opacity: 0.7;
    }
    .type {
      display: inline-block;
      width: 120px;
      line-height: 30px;
      font-size: 16px;
      padding-right: 20px;
      text-align: right;
      text-transform: uppercase;
      color: white;
      background-color: $history-purchase-type;
      img { float: left; }
      &.refund { background-color: $history-refund-type; }
    }
    .values {
      float: right;
      padding: 10px;
      font-size: 2em;
      text-align: right;
      .extra {
        font-size: 0.4em;
        font-color: $text-faded;
      }
    }
    .notes {
      padding: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      img {
        vertical-align: middle;
      }
    }
  }
}
.history-record {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  z-index: 1; // Make sure drop-shadow is in right spot
  border-left: 1px solid #2e2e2e;
  @include box-shadow(5px, 0, 10px, 5px, #2e2e2e);
  .heading {
    height: 60px;
    background-color: $primary-color;
    line-height: 60px;
    text-align: center;
    font-size: 30px;
    color: $primary-text-color;
    @include box-shadow(0, 0, 5px, 0, #2e2e2e);
  }
  .receipt-wrapper {
    flex: 4;
    padding: 10px;
    overflow-y: auto;
    text-align: center;
    .receipt {
      display: inline-block;
      font-family: Courier New, Courier, monospace;
      white-space: pre;
      text-align: left;
    }
  }
  .notes {
    flex: 1;
    padding: 10px 30px;
    overflow-y: auto;
    font-family: Courier New, Courier, monospace;
    white-space: pre;
    border-top: 1px solid $menu-bg-color;
  }
  .footer {
    display: flex;
    flex-direction: row;
    height: 60px;
    line-height: 60px;
    color: $primary-text-color;
    background-color: $menu-bg-color;
    @include box-shadow(0, 0, 5px, 0, #2e2e2e);
    * {
      flex: 1;
      text-align: center;
    }
  }
}
.history-list,
.history-record {
  .empty {
    flex: 1;
    height: 100%;
    display: flex;
    justify-content: center;
    font-style: italic;
    color: $text-faded;
    div {
      align-self: center;
    }
  }
}
