
.setup {
  flex-grow: 1;
  background: $primary-bg-color url("/images/setup-bg.png") no-repeat center;
  background-size: cover;
  text-align: center;
  color: $primary-text-color;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .highlight {
    display: block;
    margin-top: 10px;
    color: $primary-text-color;
    font-weight: bold;
  }
  h1 {
    font-weight: 100;
    font-size: 2.4em;
    margin-bottom: 0;
    sup {
      font-size: 0.4em;
    }
  }
  .slogan {
    margin-bottom: -20px;
  }
  .setup-box {
    margin: auto;
    font-size: 1.5em;
    font-weight: 300;
    label {
      display: inline-block;
      width: 200px;
      text-align: right;
      padding: 5px;
      box-sizing: border-box;
    }
    input {
      margin: 10px auto;
      width: 250px;
      padding: 5px;
      box-sizing: border-box;
      font-size: 0.8em;
      color: $primary-text-color;
      border: 1px solid $primary-text-color;
      background-color: $setup-button-bg;
      @include border-radius(4px);
    }
    p, .auth-form {
      font-size: 24px;
      margin: 40px auto;
      max-width: 500px;
    }
    button {
      background-color: $setup-button-bg;
      height: 45px;
      margin-top: 20px;
      line-height: 45px;
      font-size: 0.8em;
      padding: 0 30px;
      min-width: 140px;
      text-transform: uppercase;
      font-weight: 300;
      @include border-radius(4px);
      color: $primary-text-color;
      border: 1px solid $primary-text-color;
      &.primary {
        background-color: $setup-button-bg-primary;
      }
    }
    .left {
      float: left;
    }
    .right {
      float: right;
    }
  }
  .help-button {
    background-color: rgba(71, 71, 71, 0.7);
    border-radius: 20px;
    padding: 2px;
    width: 24px;
    font-size: 0.8em;
    display: inline-block;
    text-align: center;
  }
}
