
@mixin inset-box-shadow($hor-offset, $ver-offset, $blur, $spread, $color) {
  -moz-box-shadow:    inset $hor-offset $ver-offset $blur $spread $color;
  -webkit-box-shadow: inset $hor-offset $ver-offset $blur $spread $color;
  box-shadow:         inset $hor-offset $ver-offset $blur $spread $color;
}

@mixin box-shadow($hor-offset, $ver-offset, $blur, $spread, $color) {
  -moz-box-shadow:    $hor-offset $ver-offset $blur $spread $color;
  -webkit-box-shadow: $hor-offset $ver-offset $blur $spread $color;
  box-shadow:         $hor-offset $ver-offset $blur $spread $color;
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}
